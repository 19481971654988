import { Alert, Box, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import authenticationServiceHook from "../../../hooks/authenticationServiceHook";

interface Props {
  disabled: boolean;
  defaultEmail?: string;
  userEmail?: string;
  error?: string;
  onChangeSaveEnabledState?: any;
  onNewEmailInput?: any;
}

export const NewEmail = ({
  disabled,
  defaultEmail,
  userEmail,
  error,
  onChangeSaveEnabledState,
  onNewEmailInput,
}: Props) => {
  const [isDisabled, setIsDisabled] = useState(false);
  const [saveEnabled, setSaveEnabled] = useState(false);

  const [showError, setShowError] = useState((error ?? "") !== "");
  const [errorMessage, setErrorMessage] = useState(error ?? "");

  const [email, setEmail] = useState(userEmail);
  const [currentEmail, setCurrentEmail] = useState(defaultEmail);
  const [confirmEmail, setConfirmEmail] = useState("");

  const { accessToken, user } = authenticationServiceHook();

  const isEmail = (emailInput: string) =>
    /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(emailInput);

  useEffect(() => {
    setIsDisabled(disabled);
  }, [disabled]);

  useEffect(() => {
    if ((error ?? "") !== errorMessage) {
      setErrorMessage(error!);
      setShowError(error! !== "");
    }
    if (!user.email_verified) {
      setErrorMessage("LoggedIn user email must be verified.");
      setShowError(true);
    }
  }, [error]);

  useEffect(() => {
    if (email !== undefined && !currentEmail) {
      setCurrentEmail(email);
    }
  }, [email]);

  useEffect(() => {
    onChangeSaveEnabledState(saveEnabled);
  }, [saveEnabled, onChangeSaveEnabledState]);

  useEffect(() => {
    onNewEmailInput(email);
  }, [email, onNewEmailInput]);

  const handleClearCurrentEmail = (emailInput: string) => {
    if (email !== undefined && emailInput === currentEmail) {
      setEmail("");
    }
  };

  const handleEmptyToCurrentEmail = (emailInput: string) => {
    if (email !== undefined && emailInput === "") {
      setEmail(currentEmail);
    }
  };

  const handleEmailInput = (email: string) => {
    setEmail(email);

    onValidateConfirmationEmail(email, confirmEmail);
  };

  const handleConfirmEmailInput = (confirmEmail: string) => {
    setConfirmEmail(confirmEmail);

    onValidateConfirmationEmail(email, confirmEmail);
  };

  const onValidateConfirmationEmail = (
    email: string | undefined,
    confirmEmail: string | undefined
  ) => {
    if (user.email_verified) {
      if (email === undefined) {
        setErrorMessage("No e-mail provided.");
        setShowError(true);
        setSaveEnabled(false);
        return;
      }

      if (confirmEmail === undefined) {
        setSaveEnabled(false);
        return;
      }

      if (isEmail(email) && isEmail(confirmEmail)) {
        var emVf = user.email_verified;
        // valid e-mail addresses
        if (email !== confirmEmail) {
          // mismatching e-mail addresses

          setErrorMessage("E-mails do not match.");
          setShowError(true);
          setSaveEnabled(false);
        } else {
          // matching e-mail addresses

          if (email === currentEmail) {
            // e-mail no different from initial

            setSaveEnabled(false);
          } else {
            // e-mail different from initial

            setSaveEnabled(true);
          }

          setShowError(false);
        }
      } else {
        // invalid e-mail addresses

        setSaveEnabled(false);

        if (!isEmail(email) && email !== "") {
          setErrorMessage("E-mail is in invalid format.");
          setShowError(true);
        } else if (!isEmail(confirmEmail) && confirmEmail !== "") {
          setErrorMessage("E-mail is not matched.");
          setShowError(true);
        } else {
          setShowError(false);
        }
      }
    }
  };

  return (
    <>
      <Box padding="12px">
        <TextField
          fullWidth
          size="small"
          value={email}
          label="Email"
          margin="dense"
          required={true}
          disabled={isDisabled}
          onFocus={(event) => handleClearCurrentEmail(event?.target.value)}
          onBlur={(event) => handleEmptyToCurrentEmail(event?.target.value)}
          onChange={(event) => handleEmailInput(event.target.value)}
          error={!email || email === currentEmail}
        ></TextField>
      </Box>

      <Box padding="12px" marginBottom="8px">
        <TextField
          fullWidth
          size="small"
          defaultValue=""
          value={confirmEmail}
          label="Confirm Email"
          margin="dense"
          required={true}
          disabled={isDisabled}
          onChange={(event) => handleConfirmEmailInput(event.target.value)}
          error={!confirmEmail}
        ></TextField>
      </Box>

      <Box padding="12px" marginBottom="8px">
        {showError && <Alert severity="error">{errorMessage}</Alert>}
      </Box>

      <Box padding="12px" minWidth="350px" marginTop="32px">
        <Typography
          variant="body1"
          fontFamily="inter"
          fontWeight="600"
          color="#E57A00"
        >
          Attention
        </Typography>
        <Typography variant="body2" fontFamily="inter" marginTop="8px">
          Please notice that changing your email will disconnect you from all
          the organizations and products you’re connected to, including this
          one.
        </Typography>
      </Box>
    </>
  );
};
